﻿'use strict'

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
require('mmenu-js');

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeNavigation
    * @description Setup the navigation for the site.  Event handlers, etc
    **/
    var initializeMobileNavigation = function () {

        //console.log("topNavigation initialized");
        const menu = new Mmenu("#bl-mobileMenu", {
          
            navbar: {
                add: true
            },
            "navbars": [],
            "offCanvas": {
                "position": "right"
            }
        });

        var API = menu.API;

        var $hamburger = $(".hamburger");
        $hamburger.on("click", function () {
            if ($(this).hasClass('is-active')) {
                API.close();
            } else {
                API.open();
            }
        });

        API.bind("open:before", function () {
            setTimeout(function () {
                $hamburger.addClass("is-active");
                $hamburger.attr('aria-label', 'close mobile menu');
            }, 100);
        });
        API.bind("close:before", function () {
            setTimeout(function () {
                $hamburger.removeClass("is-active");
                $hamburger.attr('aria-label', 'open mobile menu');
            }, 100);
        });

    };

    // Return an object exposing the public items
    return {
        initialize: initializeMobileNavigation
    };

}());