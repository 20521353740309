﻿'use strict'

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeLocationHighlight
    **/
    const initializeLocationHighlight = function () {
        $(document).ready(function () {
            // Assuming your radio buttons have a class 'category-radio'
            $('input.location-category').click(function () {
                // Get the selected category
                var selectedCategory = $(this).val();

                // Remove the new class from all locations
                $('.location-card').removeClass('highlighted');

                // Add the new class to locations that match the selected category
                $('.location-card.' + selectedCategory).addClass('highlighted');
            });

            $('.location-clear').click(function () {
                $('.location-card').removeClass('highlighted');
                $('.location-category').prop('checked', false);
            });
        });
    }

    // Return an object exposing the public items
    return {
        initialize: initializeLocationHighlight
    };

}());