'use strict'

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name contactExpand
    **/
    var contactExpand = function () {
        var $contactToggle = $("#contact-expand");
        var $contactBottom = $(".contact-footer-map")

        $contactToggle.on("click", function () {
            var contactSection = $('.contact-footer-section');
            contactSection.toggleClass('contact-open');

            // Get the current text of the button
            var currentButtonText = $contactToggle.text();

            // Toggle the button text based on the presence of 'contact-open' class
            if (contactSection.hasClass('contact-open')) {
                $contactToggle.html('<span class="fa-solid fa-envelope"></span> Close');
            } else {
                $contactToggle.html('<span class="fa-solid fa-envelope"></span> Contact Us');
            }
        });   
    };

    // Return an object exposing the public items
    return {
        initialize: contactExpand
    };

}());