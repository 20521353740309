'use strict'

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializePopup
    **/
    var initializePopup = function () {
        $('[data-fancybox="gallery"]').fancybox({
            // Add any customization options here
        });
    };

    // Return an object exposing the public items
    return {
        initialize: initializePopup
    };

}());